<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("product.productActions") }}</span
        >
      </v-col>
    </v-row>
    <v-row
      no-gutters
      v-if="productId && !!model.is_locked && model.user_id !== user.id"
    >
      <v-col cols="12" class="my-3">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1 error--text"
          >*{{
            $t("common.documentReadOnlyModeNotice", { userId: model.user.name })
          }}</span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-card-title class="py-1">
            <h5
              style="max-width: 220px"
              :class="
                productId
                  ? 'px-1 text-truncate font-italic primary--text font-weight-bold'
                  : 'primary--text text--darken-1'
              "
              class="font-weight-regular"
            >
              {{
                productId
                  ? originModel.item_code && originModel.description
                    ? originModel.item_code + " - " + originModel.description
                    : ""
                  : $t("product.addProduct")
              }}
            </h5>
            <v-spacer />
            <v-menu
              bottom
              v-if="productId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="
                    remarksList && remarksList.length > 0
                      ? 'success'
                      : 'primary'
                  "
                  class="ma-2"
                  outlined
                  small
                  @click="remarksDialog = true"
                  :disabled="
                    accessRight.length < 2 && accessRight.includes('show')
                  "
                >
                  {{ $t("remarks.remarks") }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("quotation.options") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="accessRight.includes('create')"
                  dense
                  class="primary--text"
                  @click="copyProduct"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-content-copy
                      </v-icon>
                      {{ $t("quotation.copy") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="actDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"> mdi-pulse </v-icon>
                      {{ $t("quotation.activityLog") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="attachedDocumentsDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-file-document
                      </v-icon>
                      {{ $t("employee.attachedDocuments") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  v-if="accessRight.includes('delete')"
                  class="error--text"
                  @click="
                    (confirmationDialog = true), (delete_item = productId)
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="error--text">
                        mdi-delete-sweep-outline
                      </v-icon>
                      {{ $t("common.delete") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              color="primary"
              small
              :loading="loading"
              :disabled="
                !valid ||
                (!!model.is_locked && model.user_id !== user.id) ||
                (accessRight.length < 2 && accessRight.includes('show'))
              "
              @click="saveProduct"
            >
              {{ $t("product.save") }}
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="text-h5 pa-0">
            <FormSkeleton
              v-if="
                (productId && Object.keys(productById).length <= 0) ||
                (copiedProduct && copyFormLoading)
              "
            />
            <v-form
              v-else
              ref="form"
              v-model="valid"
              lazy-validation
              :disabled="accessRight.length < 2 && accessRight.includes('show')"
            >
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="12">
                  <v-stepper class="pb-0" v-model="e6" vertical non-linear flat>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 1"
                      editable
                      @click.native="accordionStepper(e6)"
                      step="1"
                      :rules="[
                        (value) => !!model.item_code,
                        (value) => !!model.description,
                        (value) =>
                          lessThanValidation(
                            $t('product.field.foreignDescription'),
                            $t('common.less'),
                            200,
                            model.foreign_description
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('product.field.itemDescription'),
                            $t('common.less'),
                            4000,
                            model.description
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('product.field.barcode'),
                            $t('common.less'),
                            100,
                            model.barcode
                          ),
                      ]"
                    >
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("product.basicDetails") }}
                      </h6>
                    </v-stepper-step>

                    <v-stepper-content
                      step="1"
                      class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            class="asterisk"
                            hide-details="auto"
                            v-model.trim="model.item_code"
                            :label="$t('product.field.itemCode')"
                            :rules="itemCodeValid"
                            required
                          />
                          <v-textarea
                            class="asterisk"
                            hide-details="auto"
                            v-model.trim="model.description"
                            rows="1"
                            :label="$t('product.field.itemDescription')"
                            :rules="descriptionValid"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model.trim="model.foreign_description"
                            :label="$t('product.field.foreignDescription')"
                            :rules="[
                              lessThanValidation(
                                $t('product.field.foreignDescription'),
                                $t('common.less'),
                                200,
                                model.foreign_description
                              ),
                            ]"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            hide-details="auto"
                            v-model.trim="model.barcode"
                            :label="$t('product.field.barcode')"
                            :rules="[
                              lessThanValidation(
                                $t('product.field.barcode'),
                                $t('common.less'),
                                100,
                                model.barcode
                              ),
                            ]"
                          />
                          <v-select
                            hide-details="auto"
                            v-model="selectedGroup"
                            :items="groupValue"
                            item-text="description"
                            item-value="id"
                            return-object
                            :menu-props="{ bottom: true, offsetY: true }"
                            :label="$t('product.field.group')"
                          />
                          <v-file-input
                            hide-details="auto"
                            @change="previewImage"
                            v-model="profileImage"
                            :rules="filesValid"
                            accept="image/*"
                            :label="$t('product.field.selectPicture')"
                          >
                            <template v-slot:append-outer>
                              <div>
                                <v-btn
                                  v-if="profileUrl"
                                  icon
                                  @click="imageViewDialog = true"
                                >
                                  <v-icon color="primary"
                                    >mdi-eye-outline</v-icon
                                  >
                                </v-btn>
                              </div>
                              <div>
                                <v-btn
                                  v-if="productId && model.media_id"
                                  icon
                                  @click="deleteProfileImage(model.media_id)"
                                >
                                  <v-icon color="error"
                                    >mdi-delete-sweep-outline</v-icon
                                  >
                                </v-btn>
                              </div>
                            </template>
                          </v-file-input>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 my-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            disabled
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 2"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 2"
                      @click.native="accordionStepper(e6)"
                      editable
                      :rules="[
                        (value) =>
                          lessThanValidation(
                            $t('product.field.lastSaleCustomer'),
                            $t('common.less'),
                            15,
                            model.last_sale_customer
                          ),
                        (value) =>
                          !model.last_sale_price ||
                          (/^[0-9]\d{0,9}(\.\d{1,2})?%?$/.test(
                            model.last_sale_price
                          ) &&
                            lessThanValidation(
                              $t('product.field.lastSalePrice'),
                              $t('common.less'),
                              8,
                              model.last_sale_price
                            )),
                        (value) =>
                          lessThanValidation(
                            $t('product.field.lastPurchaseSupllier'),
                            $t('common.less'),
                            15,
                            model.last_purchase_supllier
                          ),
                        (value) =>
                          !model.last_purchase_pice ||
                          (/^[0-9]\d{0,9}(\.\d{1,2})?%?$/.test(
                            model.last_purchase_pice
                          ) &&
                            lessThanValidation(
                              $t('product.field.lastPurchasePrice'),
                              $t('common.less'),
                              8,
                              model.last_purchase_pice
                            )),
                      ]"
                      step="2"
                    >
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("product.otherDetails") }}
                      </h6>
                    </v-stepper-step>

                    <v-stepper-content
                      step="2"
                      class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            hide-details="auto"
                            v-model="model.last_sale_customer"
                            :label="$t('product.field.lastSaleCustomer')"
                            :rules="[
                              lessThanValidation(
                                $t('product.field.lastSaleCustomer'),
                                $t('common.less'),
                                15,
                                model.last_sale_customer
                              ),
                            ]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.last_sale_price"
                            @input="
                              model.last_sale_price =
                                $event !== '' ? $event : null
                            "
                            :label="$t('product.field.lastSalePrice')"
                            :rules="
                              integerValueValid.concat(
                                lessThanValidation(
                                  $t('product.field.lastSalePrice'),
                                  $t('common.less'),
                                  8,
                                  model.last_sale_price
                                )
                              )
                            "
                            required
                          />
                          <v-menu
                            ref="lastSaleDate"
                            v-model="lastSaleDate"
                            :close-on-content-click="false"
                            :return-value.sync="model.last_sale_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="formattedSaleDate"
                                :label="$t('product.field.lastSaleDate')"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="model.last_sale_date"
                              @change="
                                $refs.lastSaleDate.save(model.last_sale_date)
                              "
                            >
                              <v-btn
                                small
                                class="primary"
                                @click="$refs.lastSaleDate.save(todayDate())"
                                >{{ $t("route.today") }}</v-btn
                              >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            hide-details="auto"
                            v-model="model.last_purchase_supllier"
                            :label="$t('product.field.lastPurchaseSupllier')"
                            :rules="[
                              lessThanValidation(
                                $t('product.field.lastPurchaseSupllier'),
                                $t('common.less'),
                                15,
                                model.last_purchase_supllier
                              ),
                            ]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.last_purchase_pice"
                            @input="
                              model.last_purchase_pice =
                                $event !== '' ? $event : null
                            "
                            :label="$t('product.field.lastPurchasePrice')"
                            :rules="
                              integerValueValid.concat(
                                lessThanValidation(
                                  $t('product.field.lastPurchasePrice'),
                                  $t('common.less'),
                                  8,
                                  model.last_purchase_pice
                                )
                              )
                            "
                            required
                          />
                          <v-menu
                            ref="lastPurchaseDate"
                            v-model="lastPurchaseDate"
                            :close-on-content-click="false"
                            :return-value.sync="model.last_purchase_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="formattedPurchaseDate"
                                :label="$t('product.field.lastPurchaseDate')"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="model.last_purchase_date"
                              @change="
                                $refs.lastPurchaseDate.save(
                                  model.last_purchase_date
                                )
                              "
                            >
                              <v-btn
                                small
                                class="primary"
                                @click="
                                  $refs.lastPurchaseDate.save(todayDate())
                                "
                                >{{ $t("route.today") }}</v-btn
                              >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-checkbox
                            hide-details="auto"
                            v-model="model.stock_item"
                            :label="$t('product.field.stockItem')"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 my-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 1"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 3"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 3"
                      editable
                      @click.native="accordionStepper(e6)"
                      :rules="[
                        (value) =>
                          lessThanValidation(
                            $t('product.field.saleAccountingNo'),
                            $t('common.less'),
                            16,
                            model.sale_accounting_no
                          ),
                        (value) =>
                          !model.sale_price ||
                          (/^[0-9]\d{0,9}(\.\d{1,2})?%?$/.test(
                            model.sale_price
                          ) &&
                            lessThanValidation(
                              $t('product.field.salePrice'),
                              $t('common.less'),
                              8,
                              model.sale_price
                            )),
                        (value) =>
                          lessThanValidation(
                            $t('product.field.purchaseAccountingNo'),
                            $t('common.less'),
                            16,
                            model.purchase_accounting_no
                          ),
                        (value) =>
                          !model.minimum_sale_price ||
                          (/^[0-9]\d{0,9}(\.\d{1,2})?%?$/.test(
                            model.minimum_sale_price
                          ) &&
                            lessThanValidation(
                              $t('product.field.minimumSalePrice'),
                              $t('common.less'),
                              8,
                              model.minimum_sale_price
                            )),
                        (value) =>
                          !model.purchase_price ||
                          (/^[0-9]\d{0,9}(\.\d{1,2})?%?$/.test(
                            model.purchase_price
                          ) &&
                            lessThanValidation(
                              $t('product.field.purchasePrice'),
                              $t('common.less'),
                              8,
                              model.purchase_price
                            )),
                      ]"
                      step="3"
                    >
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("product.financial") }}
                      </h6>
                    </v-stepper-step>

                    <v-stepper-content
                      step="3"
                      class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            hide-details="auto"
                            v-model="model.sale_accounting_no"
                            :label="$t('product.field.saleAccountingNo')"
                            :rules="[
                              lessThanValidation(
                                $t('product.field.saleAccountingNo'),
                                $t('common.less'),
                                16,
                                model.sale_accounting_no
                              ),
                            ]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.sale_price"
                            @input="
                              model.sale_price = $event !== '' ? $event : null
                            "
                            :label="$t('product.field.salePrice')"
                            :rules="
                              integerValueValid.concat(
                                lessThanValidation(
                                  $t('product.field.salePrice'),
                                  $t('common.less'),
                                  8,
                                  model.sale_price
                                )
                              )
                            "
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.minimum_sale_price"
                            @input="
                              model.minimum_sale_price =
                                $event !== '' ? $event : null
                            "
                            :label="$t('product.field.minimumSalePrice')"
                            :rules="
                              integerValueValid.concat(
                                lessThanValidation(
                                  $t('product.field.minimumSalePrice'),
                                  $t('common.less'),
                                  8,
                                  model.minimum_sale_price
                                )
                              )
                            "
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.vat_recognized_percentage"
                            @input="
                              model.vat_recognized_percentage =
                                $event !== '' ? $event : null
                            "
                            :label="$t('product.field.vatRecognizedPercentage')"
                            :rules="
                              integerValueValid.concat([
                                (value) =>
                                  /^[0-9]+$/.test(value) ||
                                  $t('formRules.numericValue'),
                                (value) =>
                                  value <= 100 ||
                                  $t(
                                    'product.field.maximumVatRecogPercentage',
                                    { max: 100 }
                                  ),
                                lessThanValidation(
                                  $t('product.field.vatRecognizedPercentage'),
                                  $t('common.less'),
                                  8,
                                  model.vat_recognized_percentage
                                ),
                              ])
                            "
                            :disabled="model.item_without_vat"
                            type="number"
                            required
                          />
                          <div class="d-flex">
                            <v-btn
                              color="primary"
                              class="my-3"
                              outlined
                              small
                              @click="customerPriceDialog = true"
                              :disabled="
                                !productId ||
                                (accessRight.length < 2 &&
                                  accessRight.includes('show'))
                              "
                            >
                              {{ $t("product.customerPrice") }}
                            </v-btn>
                            <v-btn
                              color="primary"
                              class="my-3 mx-2"
                              outlined
                              small
                              @click="supplierPriceDialog = true"
                              :disabled="
                                !productId ||
                                (accessRight.length < 2 &&
                                  accessRight.includes('show'))
                              "
                            >
                              {{ $t("product.supplierPrice") }}
                            </v-btn>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            hide-details="auto"
                            v-model="model.purchase_accounting_no"
                            :label="$t('product.field.purchaseAccountingNo')"
                            :rules="[
                              lessThanValidation(
                                $t('product.field.purchaseAccountingNo'),
                                $t('common.less'),
                                16,
                                model.purchase_accounting_no
                              ),
                            ]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.purchase_price"
                            @input="
                              model.purchase_price =
                                $event !== '' ? $event : null
                            "
                            :label="$t('product.field.purchasePrice')"
                            :rules="
                              integerValueValid.concat(
                                lessThanValidation(
                                  $t('product.field.purchasePrice'),
                                  $t('common.less'),
                                  8,
                                  model.purchase_price
                                )
                              )
                            "
                            required
                          />
                          <div class="d-flex">
                            <v-checkbox
                              class="me-12"
                              hide-details="auto"
                              v-model="model.item_without_vat"
                              :label="$t('product.field.itemWithoutVAT')"
                              @change="handleVatCheckboxChange"
                            />
                            <v-checkbox
                              hide-details="auto"
                              v-model="model.vat_assets_item"
                              :label="$t('product.field.vatAssetsItem')"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 my-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 2"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 4"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 4"
                      editable
                      @click.native="accordionStepper(e6)"
                      step="4"
                    >
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("remarks.remarks") }}
                      </h6>
                    </v-stepper-step>

                    <v-stepper-content
                      step="4"
                      class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            class="mt-4"
                            hide-details="auto"
                            dense
                            v-model="model.general_remarks"
                            :label="$t('customer.field.generalRemarks')"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 my-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 3"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            disabled
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <ActivityLogTableDialog
        :actDialog.sync="actDialog"
        :docId.sync="productId"
        docType="Item"
      />
      <ImageViewDialog
        v-if="profileUrl"
        :profileUrl.sync="profileUrl"
        :dialog.sync="imageViewDialog"
      />
      <RemarksDialog
        :dialog.sync="remarksDialog"
        :docId="productId"
        :docType="34"
      />
      <CustomerPriceDialog
        :customerPriceDialog.sync="customerPriceDialog"
        :docId="productId"
      />
      <SupplierPriceDialog
        :supplierPriceDialog.sync="supplierPriceDialog"
        :docId="productId"
      />
      <AttachedDocumentsDialog
        :docId="productId"
        docType="Item"
        :dialog.sync="attachedDocumentsDialog"
      />
      <confirmation-dialog
        :dialog.sync="confirmationDialog"
        :delete_item.sync="delete_item"
        @deleteItem="deleteItem"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuxiliaryZtableValueByName, changedFormData } from "@/utils";
import ImageViewDialog from "./Dialog/ImageViewDialog.vue";
import ActivityLogTableDialog from "@/components/activityLog/Dialogs/ActivityLogTableDialog.vue";
import FormSkeleton from "@/components/skeleton/FormSkeleton";
import Alert from "@/components/Alert";
import { formatDateDDMMYYYY, todayDate } from "../../../utils";
import RemarksDialog from "@/components/RemarksDialog";
import AttachedDocumentsDialog from "@/components/AttachedDocumentsDialog";
import CustomerPriceDialog from "@/components/customerPriceItem/Dialogs/CustomerPriceDialog.vue";
import SupplierPriceDialog from "@/components/customerPriceItem/Dialogs/SupplierPriceDialog.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "ProductAction",
  components: {
    ImageViewDialog,
    ActivityLogTableDialog,
    FormSkeleton,
    Alert,
    RemarksDialog,
    AttachedDocumentsDialog,
    CustomerPriceDialog,
    SupplierPriceDialog,
    ConfirmationDialog,
  },
  mixins: [validationMixin],
  data() {
    return {
      message: "",
      alert: false,
      loading: false,
      valid: false,
      model: {
        stock_item: true,
      },
      formattedSaleDate: "",
      formattedPurchaseDate: "",
      originModel: {},
      previousStep: 1,
      e6: 1,
      lastSaleDate: false,
      lastPurchaseDate: false,
      groupValue: [],
      selectedGroup: null,
      profileImage: null,
      profileUrl: null,
      imageViewDialog: false,
      actDialog: false,
      deleteProfileId: null,
      copyFormLoading: true,
      attachedDocumentsDialog: false,
      remarksDialog: false,
      customerPriceDialog: false,
      supplierPriceDialog: false,
      confirmationDialog: false,
      delete_item: null,
    };
  },
  computed: {
    integerValueValid() {
      return [
        (v) =>
          !v ||
          /^[0-9]\d{0,9}(\.\d{1,2})?%?$/.test(v) ||
          this.$t("formRules.integerValue"),
      ];
    },
    itemCodeValid() {
      return [
        (v) => !!v || this.$t("formRules.itemCodeRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.itemCodeRequired"),
        (v) =>
          !v ||
          (v && v.length <= 30) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("product.field.itemCode"),
            type: this.$t("common.less"),
            size: 30,
          }),
      ];
    },
    descriptionValid() {
      return [
        (v) => !!v || this.$t("formRules.itemDescriptionRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.itemDescriptionRequired"),
        (v) =>
          !v ||
          (v && v.length <= 4000) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("product.field.itemDescription"),
            type: this.$t("common.less"),
            size: 4000,
          }),
      ];
    },
    filesValid() {
      return [(v) => !v || v.size <= 2000000 || this.$t("formRules.imageSize")];
    },
    productId() {
      return this.$route.params.product_id;
    },
    copiedProduct() {
      return this.$route.params.copiedModel;
    },
    ...mapGetters({
      productById: "product/productById",
      productQuery: "product/productQuery",
      accessRight: "accessRight",
      profileById: "profile/profileById",
      remarksList: "remarks/remarksList",
      user: "user",
      moreThanOneUserLogout: "moreThanOneUserLogout",
    }),
  },
  watch: {
    "model.last_purchase_date"(val) {
      this.formattedPurchaseDate = this.profileById.date_format
        ? val
        : formatDateDDMMYYYY(val);
    },
    "model.last_sale_date"(val) {
      this.formattedSaleDate = this.profileById.date_format
        ? val
        : formatDateDDMMYYYY(val);
    },
    profileById(val) {
      this.formattedPurchaseDate = val.date_format
        ? this.model.last_purchase_date
        : formatDateDDMMYYYY(this.model.last_purchase_date);
      this.formattedSaleDate = val.date_format
        ? this.model.last_sale_date
        : formatDateDDMMYYYY(this.model.last_sale_date);
    },
    e6() {
      if (this.valid) {
        this.alert = false;
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    productById() {
      if (Object.keys(this.productById).length > 0) {
        this.model = Object.assign({}, this.model, this.productById);
        if (this.model.last_sale_date) {
          this.model.last_sale_date = new Date(this.model.last_sale_date)
            .toISOString()
            .substring(0, 10);
        }
        if (this.model.last_purchase_date) {
          this.model.last_purchase_date = new Date(
            this.model.last_purchase_date
          )
            .toISOString()
            .substring(0, 10);
        }
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "Item",
          id: this.model.id,
          is_locked: false,
        });
        this.$store.dispatch("LogOut");
        this.$router.push({ name: "login" });
      }
    },
  },
  async created() {
    window.addEventListener("beforeunload", this.handler);
  },
  async mounted() {
    if (this.$route.params.copiedModel) {
      this.model = this.$route.params.copiedModel;
      this.selectedGroup = this.model.group;
      if (this.model.media) {
        this.profileUrl = this.model.media.file_path;
      }
      this.model.item_code = null;
      this.copyFormLoading = false;
    } else if (this.$route.params.product_id) {
      await this.$store.dispatch(
        "product/GetProductById",
        this.$route.params.product_id
      );
      if (!this.model.is_locked && this.$route.params.product_id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "Item",
          id: this.$route.params.product_id,
          is_locked: true,
        });
      }
      this.updateProductData();
    }
    if (this.productId) {
      this.$store.dispatch("remarks/GetRemarks", {
        where: "document_type|" + 34,
        where_and: "document_id|" + this.productId,
      });
    }
    this.$store.dispatch("profile/GetCompanyById");
    let tableNameValue = await getAuxiliaryZtableValueByName(3);
    if (Object.keys(tableNameValue).length > 0) {
      this.groupValue = tableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
    // Check if the item is being edited and the checkbox is already checked
    if (this.model.item_without_vat) {
      this.model.vat_recognized_percentage = 0;
    }
  },
  beforeDestroy() {
    if (
      (this.model.id &&
        !this.moreThanOneUserLogout &&
        this.model.is_locked &&
        this.model.user_id == this.user.id) ||
      (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
    ) {
      this.$store.dispatch("PostDocumentOpenAction", {
        table: "Item",
        id: this.model.id,
        is_locked: false,
      });
    }
    this.$refs.form ? this.$refs.form.reset() : "";
    (this.model = {}), this.$store.commit("product/SET_PRODUCT_BY_ID", {});
    this.$store.commit("SET_USER_LOGOUT", false);
    window.removeEventListener("beforeunload", this.handler);
  },
  methods: {
    todayDate: todayDate,
    async handler(event) {
      if (
        (this.model.id &&
          !this.moreThanOneUserLogout &&
          this.model.is_locked &&
          this.model.user_id == this.user.id) ||
        (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
      ) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "Item",
          id: this.model.id,
          is_locked: false,
        });
      }
      event.preventDefault();
    },
    updateProductData() {
      this.selectedGroup = this.model.group;
      if (this.model.media) {
        this.profileUrl = this.model.media.file_path;
      }
    },
    callBackFunction() {
      this.$router.push({
        name: "Product",
        query: {
          ...this.productQuery,
        },
      });
    },
    copyProduct() {
      let copyModel = this.originModel;
      this.$router.push({
        name: "addItem",
        params: {
          copiedModel: copyModel,
        },
      });
    },
    accordionStepper(current) {
      if (this.previousStep == current && this.previousStep != 4) {
        this.e6 = 4;
        this.previousStep = 4;
      } else {
        this.previousStep = current;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && String(v).length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    previewImage() {
      if (this.profileImage) {
        this.profileUrl = URL.createObjectURL(this.profileImage);
      } else {
        if (this.model.media && !this.deleteProfileId) {
          this.profileUrl = this.model.media.file_path;
        } else {
          this.profileUrl = "";
        }
      }
    },
    deleteProfileImage(id) {
      this.deleteProfileId = id;
      this.model.media_id = null;
      this.profileUrl = null;
    },
    async saveProduct() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data;
        if (this.selectedGroup && Object.keys(this.selectedGroup).length > 0) {
          this.model.group_id = this.selectedGroup.id;
        }
        // upload image
        if (this.profileImage) {
          let formData = new FormData();
          formData.append("image", this.profileImage);
          if (this.model.media_id) {
            await this.$store.dispatch("media/EditMedia", {
              media_id: this.model.media_id,
              formData: formData,
            });
          } else {
            let imageData = await this.$store.dispatch(
              "media/SetMedia",
              formData
            );
            if (imageData) {
              this.model.media_id = imageData.data.id;
            }
          }
        }
        if (this.deleteProfileId) {
          this.$store.dispatch("media/DeleteMediaById", this.deleteProfileId);
        }
        if (this.$route.params.product_id) {
          // save only update model value using changedFormData from utils
          let saveData = changedFormData(this.model, this.originModel);
          data = await this.$store
            .dispatch("product/EditProduct", {
              product_id: this.$route.params.product_id,
              product: saveData,
            })
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            await this.$store.dispatch("product/GetProductById", data.data.id);
            let tableNameValue = await getAuxiliaryZtableValueByName(3);
            if (Object.keys(tableNameValue).length > 0) {
              this.groupValue = tableNameValue.ztables.sort((a, b) =>
                a.description.toLocaleLowerCase() >
                b.description.toLocaleLowerCase()
                  ? 1
                  : -1
              );
            }
            this.updateProductData();
          }
        } else if (this.$route.params.copiedModel) {
          let copyModel = this.model;
          delete copyModel.id;
          delete copyModel.created_at;
          delete copyModel.updated_at;
          data = await this.$store
            .dispatch("product/SetProduct", copyModel)
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            if (this.accessRight.includes("edit")) {
              await this.$router
                .push("/item/editItem/" + data.data.id)
                .catch(() => {});
            } else {
              this.callBackFunction();
            }
          }
        } else {
          data = await this.$store
            .dispatch("product/SetProduct", this.model)
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            if (this.accessRight.includes("edit")) {
              await this.$router
                .push("/item/editItem/" + data.data.id)
                .catch(() => {});
            } else {
              this.callBackFunction();
            }
          }
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    async deleteItem() {
      await this.$store.dispatch(
        "product/DeleteProductItemById",
        this.productId
      );
      this.$router.push("/item");
    },

    handleVatCheckboxChange() {
      if (this.model.item_without_vat) {
        this.model.vat_recognized_percentage = 0;
      }
    },
  },
};
</script>
