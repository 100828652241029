<template>
  <div>
    <v-card class="mx-auto" outlined>
      <v-card-text class="text-h5">  
        <v-card-text class="pa-0 justify-space-between" style="display: flex; align-items: center;">
          <v-btn
            color="primary"
            class="mt-2"
            outlined
            small
            @click="cutsomerPriceFormDialog = true"
            :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
          >
            {{ $t("product.addCustomerPrice") }}
          </v-btn>
          <v-icon
            class="mt-2 primary--text"
            @click="$emit('update:customerPriceDialog', false)"
            >mdi-close</v-icon
          >
        </v-card-text>
        <v-data-table
          :dense="true"
          class="custom-table mb-0 ma-2 row-pointer"
          :headers="headers"
          :items="customerPriceListData"
          fixed-header
          height="250px"
          style="word-break: break-word, overflow: auto"
          item-class="py-4"
          hide-default-footer
          @dblclick:row="editItem"
          :search="search"
          disable-pagination
        >
          <template v-slot:[`item.customer_name`]="props">
           {{props.item.customer_id}}. {{props.item.customer_name}}
          </template>
          <template v-slot:[`item.discount`]="props">
           {{props.item.discount ? props.item.discount : ""}}
          </template>
          <template v-slot:[`item.price`]="props">
           {{props.item.price ? numberWithCommas(props.item.price) : ""}}
          </template>
          <template v-slot:[`item.actions`]="props">
            <v-icon @click="confirmationDialog = true, delete_item = props.item" class="error--text">mdi-delete-sweep-outline</v-icon>
          </template>
          <template v-slot:top>
            <div class="text-body-2 primary--text mb-2">
              <v-row :dense="true">
                <v-col cols="9"> </v-col>
                <v-col cols="3" class="text-end d-flex">
                  <v-text-field
                    :full-width="false"
                    :dense="true"
                    hide-details="auto"
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('common.search')"
                    single-line
                  />
                </v-col>
              </v-row>
            </div>
            <v-divider />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <confirmation-dialog :dialog.sync="confirmationDialog" :delete_item.sync="delete_item" @deleteItem="deleteItem"/>
    <CustomerPriceForm :dialog.sync="cutsomerPriceFormDialog" :docId="docId" :customerPriceDialog.sync="customerPriceDialog" :editItemData="editItemData"/>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import ConfirmationDialog from '@/components/ConfirmationDialog';
import CustomerPriceForm from '@/components/customerPriceItem/Form/CustomerPriceForm.vue'

export default {
  name: "CustomerPriceList",
  props: ["customerPriceDialog", "docId"],
  components: {
    ConfirmationDialog,
    CustomerPriceForm,
  },
  data() {
    return {
      search: this.$route.query.search || "",
      confirmationDialog: false,
      cutsomerPriceFormDialog: false,
      delete_item: null,
      editItemData: {}
    }
  },
  async mounted() {
    await this.$store.dispatch("customerPrice/GetCustomerPriceData", "?where=item_id|" + this.docId);
  },
  computed: {
    ...mapGetters({
      accessRight: "accessRight",
      customerPriceListData: "customerPrice/customerPriceListData"
    }),
    headers() {
      return [
        { text: this.$t("product.customer"), value: "customer_name", width: "150px"},
        { text: this.$t("product.price"), value: "price", width: "100px"},
        { text: this.$t("product.discount"), value: "discount", width: "140px"},
        { text: this.$t("product.action"), value: "actions", width: "60px"},
      ]
    }
  },
  methods: {
    editItem(_, {item}) {
      this.editItemData = item
      this.cutsomerPriceFormDialog = true
    },
    numberWithCommas(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (0).toFixed(2);
    },
    async deleteItem(item) {
      await this.$store.dispatch('customerPrice/DeleteCustomerPriceDataById', item.id);
      await this.$store.dispatch("customerPrice/GetCustomerPriceData", "?where=item_id|" + this.docId);
    }
  },
};
</script>

<style scoped>
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>