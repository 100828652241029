<template>
  <v-dialog
    v-model="dialog"
    @click:outside="$emit('update:dialog', false);"
    height="300"
    width="300"
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-spacer />
        <v-btn
          icon
          @click="$emit('update:dialog', false);"
        >
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-h5 px-2 pb-2">
        <v-img max-height="300" max-width="300" :src="profileUrl" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ImageViewDialog",
  props: ["profileUrl", "dialog"],
}
</script>