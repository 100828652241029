<template>
  <div>
    <v-dialog
      content-class="custom-dialog-content"
      v-model="dialog"
      @click:outside="$emit('update:dialog', false)"
      max-width="40%"
    >
    <v-card class="mx-auto" outlined>
      <v-card-text class="pa-0 text-end">
        <v-icon
          class="mt-2 mx-2 primary--text"
          @click="$emit('update:dialog', false)"
          >mdi-close</v-icon
        >
      </v-card-text>
      <v-card-text class="text-h5 px-4">
        <v-form ref="form" v-model="valid" lazy-validation :disabled="accessRight.length < 2 && accessRight.includes('show')">
         <Alert :alert.sync="alert" :message="message" />
          <v-row>
            <v-col cols="12">
              <v-stepper class="pb-0" v-model="e6" vertical non-linear flat>
                <v-stepper-step
                  class="py-3 px-2 text-h6"
                  editable
                  step="1"
                >
                  <v-row dense>
                    <v-col cols="6" dense>
                      <h6 class="primary--text mt-2 font-weight-regular">
                        {{ $t("product.customerPrice") }}
                      </h6>
                    </v-col>
                    <v-col cols="6" class="text-end" dense>
                      <v-btn
                        color="primary"
                        small
                        :disabled="
                          !valid ||
                          (accessRight.length < 2 &&
                            accessRight.includes('show'))
                        "
                        @click="saveCustomerPrice"
                      >
                        {{ $t("contact.save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-step>
                <v-stepper-content
                  step="1"
                  class="ml-5 mb-auto pb-0 mr-3 py-2 px-2"
                >
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        class="asterisk"
                        autocomplete="new-password"
                        cache-items
                        v-model="selectedCustomer"
                        :items="customerList.data"
                        item-text="name_1"
                        item-value="id"
                        flat
                        return-object
                        hide-no-data
                        hide-details="auto"
                        single-line
                        clearable
                        @change="onCustomerChange"
                        @click:clear="onCustomerChange"
                        :search-input.sync="customerSearch"
                        :label="$t('product.customer')"
                        :rules="customerValid"
                      >
                        <template v-slot:append-outer>
                          <v-icon
                            color="primary"
                            dense
                            @click="customerDialog = true"
                          >
                            mdi-magnify
                          </v-icon>
                        </template>
                        <template v-slot:selection="data">
                          <span>{{`${data.item.id}. ${data.item.name_1}`}}</span>
                        </template>
                        <template v-slot:item="data">
                          <div
                            class="font-weight-medium"
                            style="
                              font-size: 0.8125rem;
                              display: flex;
                              align-item: center;
                            "
                          >
                            {{ `${data.item.id}. ${data.item.name_1}` }}
                          </div>
                        </template>
                        <template v-slot:append-item>
                          <div
                            v-show="hasNextPage"
                            v-intersect="infiniteScroll"
                            ref="load"
                            class="loader text-center"
                          >
                            <v-progress-circular indeterminate color="primary"/>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :class="!!price ? 'asterisk' : ''"
                        :disabled="!!discount"
                        :label="$t('product.price')"
                        required
                        v-model="price"
                        :rules="priceValid"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :class="!!discount ? 'asterisk' : ''"
                        :disabled="!!price"
                        class="mb-3"
                        :label="$t('product.discount')"
                        required
                        v-model="discount"
                        :rules="discountValid"
                      />
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <CustomerDialog @onCustomerChange="onCustomerChange" :dialog.sync="customerDialog" />
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomerDialog from "@/components/moduleTableDialog/Dialog/CustomerDialog";
import Alert from "@/components/Alert";
import { validationMixin } from '@/mixins/validationMixin';
import { intersectionMixin } from '@/mixins/intersectionMixin';
import { searchMixin } from '@/mixins/searchMixin';

export default {
  name: "CustomerPriceForm",
  props: ["dialog", "docId", "customerPriceDialog", "editItemData"],
  components: {
    CustomerDialog,
    Alert,
  },
  mixins: [validationMixin, intersectionMixin, searchMixin],
  data() {
    return {
      valid: false,
      e6: 1,
      selectedCustomer: null,
      customerDialog: false,
      customerLimit: 10,
      price: null,
      discount: null,
      customerPriceData: {},
      alert: false,
      message: "",
      customerPage: 1,
      isExist: null,
      checkIsExist: false,
    };
  },
  mounted() {
    this.$store.dispatch("customer/GetCustomer", { page: this.customerPage, limit: this.customerLimit, order_by: "name_1|asc" });
  },
  watch: {
    customerLimit() {
      this.$store.dispatch("customer/GetCustomer", {page: 1, limit: this.customerLimit, order_by: "name_1|asc"});
    },
    editItemData() {
      if (this.editItemData) {
        this.price = this.editItemData?.price ? this.editItemData?.price : null
        this.discount = this.editItemData?.discount ? this.editItemData?.discount : null
        this.selectedCustomer = {
          id: this.editItemData?.customer_id,
          name_1: this.editItemData?.customer_name,
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      accessRight: "accessRight",
      customerList: "customer/customerList",
    }),
    discountValid() {
      return [
        (v) => !v || /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) || this.$t("formRules.spaceValidValue"),
        (v) => !v || (v && String(v).length <= 6) || this.$t("formRules.lengthValidMessage", { fieldName: this.$t("product.discount"), type: this.$t("common.less"), size: 6 }),
      ];
    },
    priceValid() {
      return [
        (v) => !v || /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) || this.$t("formRules.spaceValidValue"),
        (v) => !v || (v && String(v).length <= 12) || this.$t("formRules.lengthValidMessage", { fieldName: this.$t("product.price"), type: this.$t("common.less"), size: 12 }),
      ];
    },
  },
  methods: {
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && v.length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    async onCustomerChange(customer) {
      this.checkIsExist = true;
      if (customer && typeof customer === "object" && Object.keys(customer).length > 0) {
        this.selectedCustomer = { id: customer.id, name_1: customer?.name_1 };
      } else {
        this.selectedCustomer = null;
      }
    },
    async saveCustomerPrice() {
      if (this.$refs.form.validate()) {
        this.customerPriceData = {
          customer_id: this.selectedCustomer?.id,
          customer_name: this.selectedCustomer?.name_1,
          item_id: Number(this.docId),
          price: Number(this.price),
          discount: Number(this.discount),
          id: this.editItemData?.id
        }
        if (!this.customerPriceData.id || (this.customerPriceData.id && this.checkIsExist)) {
          await this.$store.dispatch('customerPrice/CustomerIsExist', this.customerPriceData).then((res) => {
            this.isExist = res
          }) 
        }
        if (this.isExist?.type === 'success') {
          this.valid = false;
          this.message = this.isExist?.message;
          this.alert = true;
          this.$emit("update:dialog", true);
        } else {
          if (this.customerPriceData?.id) {
            this.$store.dispatch('customerPrice/EditCustomerPrice', {
              id: this.customerPriceData.id,
              customerPrice: this.customerPriceData,
            })
            this.checkIsExist = false;
          } else {
            delete this.customerPriceData.id
            await this.$store.dispatch('customerPrice/SetCustomerPriceData', this.customerPriceData)
          }
          this.$emit("update:dialog", false);
        }
      } else {
        this.valid = false;    
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.customerLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
  },
};
</script>